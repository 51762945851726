import * as React from 'react'
import { Box, Typography, Button, Grid } from '@mui/material'

import BasePage from './BasePage'
import { HKGrotesk, NotoSans, lineGroupUrl, qrCodeHeight, qrCodeWidth } from '../const'

function RegisterBlock({ children }) {
  return (
    <Grid item md={6} width='100%'>
      <Box
        component='div'
        bgcolor='black.main'
        padding={{lg: 7, md: 7, sm: 7, xs: 3.5}}
        height='100%'
        display='flex'
        flexDirection='column'
      >
        {children}
      </Box>
    </Grid>
  )
}

function RegisterButton({ children, href }) {
  return (
    <Button
      size='large'
      variant='contained'
      color='yellow'
      disableElevation={true}
      href={href}
      target='_blank'
      sx={{
        mt: 5,
        fontWeight: 900,
        fontFamily: HKGrotesk,
        width: { xl: '280px', lg: '280px', md: '250px', sm: '100%', xs: '100%' },
        fontSize: { xl: '38px', lg: '38px', md: '35px', sm: '33px', xs: '25px' },
        borderRadius: '20px',
        lineHeight: {xl: '70px', lg: '70px', md: '70px', sm: '60px', xs: '40px'},
      }}
    >
      {children}
    </Button>
  )
}

function RegisterTypography({ children, ...props }) {
  const registerFontSize = { xl: '36px', lg: '36px', md: '36px', sm: '32px', xs: '23px' }
  return (
    <Typography fontFamily={HKGrotesk} fontSize={registerFontSize} fontWeight={800} color='white.main' {...props}>
      {children}
    </Typography>
  );
}

export function SectionTitleTypography({ titleText }) {
  const sectionTitleFontSize = { xl: '70px', lg: '70px', md: '60px', sm: '50px', xs: '38px' };
  const fontBetweenPadding = 4;

  return (
    <Grid container justifyContent='center' pb={7}>
      <Grid item>
        <Typography fontSize={sectionTitleFontSize} fontStyle='italic' color='yellow.main' px={fontBetweenPadding}>
          JOIN US NOW
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          fontSize={sectionTitleFontSize}
          fontWeight={900}
          color='white.main'
          px={fontBetweenPadding}
          lineHeight='90px'
        >
          {titleText}
        </Typography>
      </Grid>
    </Grid>
  );
}

function RunningManSection({ sectionDefaultPadding, ...props }) {
  const leftCircleFontSize = '30px';
  const leftCircleHeight = '550px';
  const prizeImageHeight = '190px';
  const registerFontSize1 = {lg: '40px', md: '40px', sm: '36px', xs: '32px'};
  const registerFontSize2 = {lg: '40px', md: '40px', sm: '36px', xs: '26px'};
  const joinUsNowFontSize = '100px';

  return (
    <Box
      sx={{
        backgroundImage: "url('running_man_with_yellow_dot.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '100vh'
      }}
      padding={sectionDefaultPadding}
    >
      <Grid container>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Box mt={30} display={{ lg: 'block', md: 'none', sm: 'none', xs: 'none' }}>
            <Box
              borderRadius='100%'
              height={leftCircleHeight}
              width={leftCircleHeight}
              bgcolor='yellow.main'
              position='absolute'
              ml={-15}
              pt={10}
            >
              <Typography variant='h3' textAlign='center' fontSize={leftCircleFontSize} fontFamily={NotoSans} fontWeight={700} pb={1}>
                多樣好禮等你抽!
              </Typography>
              <Typography variant='h3' textAlign='center' fontSize={leftCircleFontSize} fontFamily={NotoSans} fontWeight={700}>
                專屬經銷商碼93198139
              </Typography>
              <PrizeBlock prizeImageHeight={prizeImageHeight} bigBoxProps={{ ml: 5, mr: -15 }} />
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} mt={9} pl={{ lg: 18, md: 10, sm: 5, xs: 0 }}>
          <Typography fontSize={registerFontSize1} color='white.main' fontWeight='bold' fontFamily={NotoSans}>
            註冊碼註冊會員前200名立刻抽大獎！
          </Typography>
          <Typography fontSize={registerFontSize2} color='white.main' fontWeight='bold' fontFamily={NotoSans}>
            加入官方LINE了解更多！
          </Typography>
          <Typography fontSize={joinUsNowFontSize} color='yellow.main' fontStyle='italic' lineHeight='110%' mt={4}>
            JOIN US
            <br />
            NOW
          </Typography>
          <Box
            component='img'
            src='/down_arrow_yellow.svg'
            position='absolute'
            right='0%'
            mr={6}
            mt={4}
            height={{ xl: '300px', lg: '300px', md: '270px', sm: '250px', xs: '220px' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export function PrizeBlock({ prizeImageHeight, bigBoxProps, imgProps }) {
  return (
    <Box
      borderRadius='20px'
      backgroundColor='rgba(50, 50, 50, 0.7)'
      mt={4}
      p={5}
      {...bigBoxProps}
    >
      <Box
        display='flex'
        justifyContent='space-evenly'
        style={{
          overflowY: 'scroll'
        }}
      >
        <Box
          px={2}
          component='img'
          src='/phone.jpg'
          height={prizeImageHeight}
          {...imgProps}
        />
        <Box
          px={2}
          component='img'
          src='/shoe.jpg'
          height={prizeImageHeight}
          {...imgProps}
        />
        <Box
          px={2}
          component='img'
          src='/clothes.png'
          height={prizeImageHeight}
          {...imgProps}
        />
      </Box>
    </Box>
  );
}

function AddLineSection() {
  const titleFontSize = {
    lg: '3.75rem',
    md: '3.75rem',
    sm: '3.5rem',
    xs: '2.25rem',
  }
  const subTitleFontSize = {
    lg: '3rem',
    md: '3rem',
    sm: '2.5rem',
    xs: '1.9rem',
  }
  const emojiTextFontSize = {
    lg: '3rem',
    md: '3rem',
    sm: '2.5rem',
    xs: '1.3rem',
  }
  return (
    <Box bgcolor='yellow.main' display='flex'>
      <Box
        width={{ lg: '66%', md: '100%', sm: '100%', 'xs': '100%' }}
        pl={{ lg: 12, sm: 7, xs: 7 }}
        pr={{ lg: 0, sm: 7, xs: 7 }}
        pt={12}
        sx={{
          backgroundImage: "url('workout_woman.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: 'right',
          backgroundSize: 'cover',
          backgroundColor: 'rgba(0,0,0,0.7)',
          backgroundBlendMode: 'darken',
          // height: '100vh'
        }}
      >
        <Box display='flex' pb={{ xl: 12, lg: 12, md: 10, sm: 7, xs: 4}}>
          <Typography component='div' fontSize={titleFontSize} variant='h2' color='yellow.main' fontWeight={800} lineHeight={1}>
            立即加入官方
            <Typography fontSize={titleFontSize} variant='h2' color='yellow.main' fontWeight={500} fontStyle='italic' pl={1} pr={2} lineHeight='100px' display='inline'>
              LINE
            </Typography>
            了解更多
          </Typography>
        </Box>
        <Box pb={{ xl: 12, lg: 12, md: 10, sm: 7, xs: 4}}>
          <Typography variant='h3' fontSize={subTitleFontSize} color='white.main' fontFamily={HKGrotesk} fontWeight={600}>
            官方 LINE
          </Typography>
          <Typography variant='h4' color='white.main' fontFamily={HKGrotesk}>
            @495zsvpw
          </Typography>
        </Box>
        <Box pb={{ xl: 8, lg: 8, md: 5, sm: 4, xs: 3}}>
          <Typography fontSize={subTitleFontSize} variant='h3' color='white.main' fontFamily={HKGrotesk} fontWeight={600} pb={2}>
            聊天室
          </Typography>
          <Typography fontSize={emojiTextFontSize} variant='h4' color='white.main' fontFamily={HKGrotesk} fontWeight={600} pb={2}>
            加入「虎爺🐯速報大廳-公告圈📣」，關注最新賣事資訊！
          </Typography>
          <Typography variant='h4' color='white.main' fontFamily={HKGrotesk} fontWeight={600}>
            點選以下連結加入 LINE 社群
          </Typography>
        </Box>

        <Grid container mb={7} justifyContent={{ xl: 'unset', lg: 'unset', md: 'unset', sm: 'center', xs: 'center'}}>
          <Grid item>
            <Box
              component='img'
              src='/line_qrcode.jpg'
              width={qrCodeWidth}
              height={qrCodeHeight}
              mr={{ lg: 3, md: 3, sm: 2, xs: 0}}
              mt={3}
            />
          </Grid>
          <Grid item>
            <Box
              component='img'
              src='/a_qrcode.jpg'
              width={qrCodeWidth}
              height={qrCodeHeight}
              mr={{ lg: 6, md: 6, sm: 0, xs: 0}}
              mt={3}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Box display='flex' flexDirection='column' height='100%'>
              <Box flexGrow={1} />
              <RegisterButton
                href={lineGroupUrl}
              >
                立即加入
              </RegisterButton>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* yellow section */}
      <Box
        display={{ lg: 'flex', md: 'none', sm: 'none', xs: 'none' }}
        justifyContent='space-between'
        width='33%'>
        <Box pt={1} pl={1} height='100%'>
          <Typography
            fontSize='160px'
            fontWeight={900}
            fontFamily='Horizon'
            fontStyle='bold'
            lineHeight='170px'
            sx={{ writingMode: 'vertical-rl', transform: 'rotate(-180deg)' }}
            textAlign='right'
          >
            JOIN<br />US
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column-reverse' alignItems='center' pb={5} pr={2}>
          <Typography fontSize='25px' fontWeight={700}>
            虎爺 三元體育
          </Typography>
          <Box mr={0}>

            <Box
              component='img'
              src='/top_arrow_white.svg'
              width='150px'
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default function Home() {
  const sectionDefaultPadding = 5;

  return (<BasePage>
    <RunningManSection
      sectionDefaultPadding={sectionDefaultPadding}
    />
    <Box
      sx={{
        backgroundImage: "url('sports_field_background.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      padding={sectionDefaultPadding}
      pt={14}
      pb={{ xl: 20, lg: 20, md: 17, sm: 14, xs: 10 }}
    >
      <SectionTitleTypography titleText='立即申辦會員' />
      <Box
        flexGrow={1}
        pt={{ xl: 10, lg: 10, md: 5, sm: 0, xs: 0 }}
        px={{
          lg: 10,
          xl: 18,
        }}
      >
        <Grid container spacing={8} justifyContent='space-between'>
          <RegisterBlock>
            <RegisterTypography>
              新手第一次註冊官方會員
            </RegisterTypography>
            <RegisterTypography>
              使用專屬經銷商碼 93198139
            </RegisterTypography>
            <RegisterTypography>
              立即抽大獎
            </RegisterTypography>
            <Box flexGrow={1} />
            <RegisterButton
              href='https://channel.sportslottery.com.tw/zh-tw/register/step1?retailerId=93198139'
            >
              立即加入
            </RegisterButton>
          </RegisterBlock>

          <RegisterBlock>
            <RegisterTypography>
              第二屆會員轉第三屆會員
            </RegisterTypography>
            <RegisterTypography>
              點選下方按鈕申辦
            </RegisterTypography>
            <RegisterTypography>
              立即抽大獎
            </RegisterTypography>
            <Box flexGrow={1} />
            <RegisterButton href='https://transfer.sportslottery.com.tw/step1?thirdrid=93198139'>
              立即加入
            </RegisterButton>
          </RegisterBlock>
        </Grid>
      </Box>
    </Box>
    <AddLineSection />
  </BasePage>)
}
