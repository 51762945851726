import { Typography } from "@mui/material";
import BasePage from "./BasePage";

export default function Membership() {
  return (
    <BasePage>
      <Typography variant="h1">
        MEMBERSHIP PAGE
      </Typography>
    </BasePage>
  );
}