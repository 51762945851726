import * as React from 'react'
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, Menu, MenuItem, Typography, Button, Grid, Divider } from '@mui/material'

import { HKGrotesk, BookletUrl, TransferTutorialUrl, ContestSheetUrl, ContestResultUrl, InGameBetUrl } from '../const';
import { Link } from 'react-router-dom';

const buttonLinks = [
  {
    buttonText: '會員註冊',
    url: '',
    subMenuList: [
      {
        buttonText: '新會員',
        url: '/register-new',
      },
      {
        buttonText: '第二屆轉第三屆會員',
        url: '/register-2-to-3',
      }
    ]
  },
  {
    buttonText: '會員專區',
    url: '/membership',
  },
  {
    buttonText: '運動彩券',
    url: '',
    subMenuList: [
      {
        buttonText: '賽事表',
        url: ContestSheetUrl,
      },
      {
        buttonText: '比賽結果',
        url: ContestResultUrl,
      },
      {
        buttonText: '場中投注',
        url: InGameBetUrl,
      }
    ]
  },
  {
    buttonText: '新手投注',
    url: '',
    subMenuList: [
      {
        buttonText: '投注手冊',
        url: BookletUrl,
      },
      {
        buttonText: '出金入金教學',
        url: TransferTutorialUrl,
      }
    ]
  },
]

function FooterDivider() {
  const theme = useTheme();
  return (
    <Divider
      variant='fullWidth'
      sx={{
        mb: 6,
        height: '2px',
        background: theme.palette.white.main
      }}
    />
  );
}

function StoreInfoBlock({ name, phone, address }) {
  return (
    <Box pb={4} pl={12}>
      <Typography variant='h5' fontFamily={HKGrotesk}>
        {name}
      </Typography>
      <Typography variant='h6' fontFamily={HKGrotesk}>
        {phone}
      </Typography>
      <Typography variant='h6' fontFamily={HKGrotesk}>
        {address}
      </Typography>
    </Box>
  )
}

function ContactInfoBlock({ lineId, email }) {
  return (
    <Box pb={4} pl={12}>
      <Typography variant='h5' fontWeight={600} fontFamily={HKGrotesk}>
        Line: {lineId}
      </Typography>
      <Typography variant='h6' fontWeight={600} fontFamily={HKGrotesk}>
        {email}
      </Typography>
    </Box>
  )
}

function MenuButton({ buttonText, url, subMenuList }) {
  const timeoutLength = 300;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [enterMenu, setEnterMenu] = React.useState(false);
  const [enterButton, setEnterButton] = React.useState(false);
  const open = enterMenu || enterButton;
  const handleEnterButton = (event) => {
    setEnterButton(true);
    setEnterMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleLeaveButton = () => {
    setTimeout(() => {
      setEnterButton(false);
    }, timeoutLength);
  }
  const handleEnterMenu = () => {
    setEnterMenu(true);
  }
  const handleLeaveMenu = () => {
    setTimeout(() => {
      setEnterMenu(false);
    }, timeoutLength);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setEnterMenu(false);
    setEnterButton(false);
  };
  return (<>
    <Button
      size='large'
      href={subMenuList ? null : url}
      color='white'
      onClick={handleEnterButton}
      onMouseOver={handleEnterButton}
      onMouseLeave={handleLeaveButton}
      sx={{
        fontSize: {
          lg: '27px', md: '27px', xs: '14px'
        },
        mx: {
          lg: 7.5, md: 2.5
        },
        fontWeight: 700
      }}
    >
      {buttonText}
    </Button>
    {subMenuList && (
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        autoFocus={false}
        MenuListProps={{
          onMouseOver: handleEnterMenu,
          onMouseLeave: handleLeaveMenu,
        }}
      >
        {subMenuList.map((data) => (
          <MenuItem
            key={`MenuItem-${data.buttonText}`}
            onClose={handleClose}
            component={Link}
            to={data.url}
            target={data.url.startsWith('/') ? '' : '_blank'}
          >
            <Typography
              sx={{
                my: 1,
                mx: 2,
                fontSize: {
                  lg: '27px', md: '23px', xs: '20px'
                },
                fontWeight: 800
              }}
            >
              {data.buttonText}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    )}
  </>);
}

export default function BasePage({ children }) {
  const titlePL = 6;
  const titleRightSmallTextFontSize = '20px';

  return (<>
    <Box
      display="flex"
      alignItems="center"
      flexWrap='wrap'
      p={3}
      pt={1}
      pb={0}
      sx={{
        bgcolor: 'yellow.main',
        height: {xl:'235px', lg:'235px', md:'235px', sm: '235px', xs: '300px'}
      }}>
      <Box
        component='img'
        position='absolute'
        src='right_arrow_black.svg'
        width='400px'
        mt={-26}
        ml={-12}
      />
      <Link to='/'>
        <Box
          component='img'
          src='taiwan_sports_lottery_logo.png'
          position='relative'
          ml={7}
          sx={{
            width: '170px'
          }}
        />
      </Link>
      <Link to='/' style={{ textDecoration: 'none' }}>
        <Box>
          <Typography variant='h4' fontSize={{lg: '36px', xs: '26px'}} color='black.main' fontWeight={900} pt={{xl: 3, lg: 3, md: 3, sm: 0, sx: 0}} pl={titlePL}>
            台灣運彩 線上會員申請
          </Typography>
          <Typography variant='h6' color='black.main' fontWeight={900} pl={titlePL + 0.2}>
            買運彩 看比賽 更精彩
          </Typography>
        </Box>
      </Link>
      {/* placeholder */}
      <Box flexGrow={1}/>
      <Box pr={{ lg: 20, md: 5 }} mt={0} display={{ lg: 'block', md: 'none', sm: 'none', xs: 'none' }}>
        <Typography variant='h4' fontSize='40px' color='black.main' fontStyle='italic'>
          TIGER DADDY
        </Typography>
        <Typography fontSize='30px' color='black.main' fontWeight={900}>
          三元體育
        </Typography>
        <Typography variant='h6' fontSize={titleRightSmallTextFontSize} color='black.main' fontStyle='italic' fontFamily={HKGrotesk}>
          LINE @495zsvpw
          <br/>
          E-MAIL tigerdaddy15818@gmail.com
        </Typography>
      </Box>
    </Box>
    <AppBar color='black' position='sticky' elevation={0} sx={{ width: '100%', height: {lg: '90px', md: '90px', sm: '90px', xs: '60px'} }}>
      <Toolbar sx={{ color: 'black', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
        {/* <Box
          // pl={{ lg: '14vw', md: 15, sm: 11 }}
          height='100%'
          display='flex'
          alignItems='center'
        > */}
          {buttonLinks.map((obj) => {
            return (
              <MenuButton
                key={`MenuButton-${obj.buttonText}`}
                buttonText={obj.buttonText}
                url={obj.url}
                subMenuList={obj.subMenuList}
              />
            )
          })}
        {/* </Box> */}
      </Toolbar>
    </AppBar>
    {children}
    <Box bgcolor='black.main' pt={10} pb={19}>
      <Grid container color='white.main'>
        <Grid item lg={3} md={4} sm={6}>
          <Box>
            <Typography variant='h4' pb={4} pl={12} fontWeight={800}>關於我們</Typography>
            <FooterDivider />

            <Typography variant='h4' pb={3} pl={12}>666666</Typography>
            <Typography variant='h4' pb={3} pl={12}>55555</Typography>
            <Typography variant='h4' pb={3} pl={12}>4444</Typography>
            <Typography variant='h4' pb={3} pl={12}>333</Typography>
          </Box>
        </Grid>

        <Grid item lg={3} md={4} sm={6}>
          <Box>
            <Typography variant='h4' pb={4} pl={12} fontWeight={800}>聯絡方式</Typography>
            <FooterDivider />
            <ContactInfoBlock
              lineId='@495zsvpw'
              email='tigerdaddy15818@gmail.com'
            />
          </Box>
        </Grid>

        <Grid item lg={3} md={4} sm={6}>
          <Box>
            <Typography variant='h4' pb={4} pl={12} fontWeight={800}>實體店面</Typography>
            <FooterDivider />
            <StoreInfoBlock
              name='順元運動彩券行'
              phone='04-22436217'
              address='台中市北屯區興安路二段500號'
            />
            <StoreInfoBlock
              name='得金牌運動彩券行'
              phone='04-25770818'
              address='台中市東勢區延平里豐勢路184號'
            />
            <StoreInfoBlock
              name='真正豪運動彩券行'
              phone='04-25815818'
              address='台中市新社區新社里興安路31號'
            />
          </Box>
        </Grid>

        <Grid item lg={3} md={4} sm={6}>
          <Box>
            <Typography variant='h4' pb={4} pl={12} fontWeight={800}>會員好禮</Typography>
            <FooterDivider />
          </Box>
        </Grid>
      </Grid>
    </Box>

    <Box bgcolor='yellow.main' p={5} display='flex'>
      <Box flexGrow={1} />
      <Box
        component='img'
        src='taiwan_sports_lottery_logo.png'
        pr={3}
        sx={{
          width: '250px',
          mt: '-200px',
        }}
      />
    </Box>
  </>)
}
