import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./pages/Home";
import { RegisterNew, Register2To3} from "./pages/Register";
import Membership from "./pages/Membership";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CssBaseline } from "@mui/material";

const pages = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/register-new',
    element: <RegisterNew />,
  },
  {
    path: '/register-2-to-3',
    element: <Register2To3 />,
  },
  {
    path: '/membership',
    element: <Membership />,
  },
];

const router = createBrowserRouter([
  ...pages
]);

const theme = createTheme({
  typography: {
    fontFamily: 'anton',
  },
  palette: {
    background: {
      default: "#F8F2E3",
    },
    white: {
      main: "#FEFEFE",
    },
    black: {
      main: "#1A1A1A",
    },
    white_yellow: {
      main: "#F8F2E3"
    },
    light_yellow: {
      main: "#EADAAE",
    },
    yellow: {
      main: "#FDE50F",
    },
    // for app bar (menu)
    brown: {
      main: "#AB4D22",
      contrastText: "#FFFFFF",
    },
    line_green: {
      main: '#5BC464',
      contrastText: '#FEFEFE'
    },
    light_brown: {
      main: "#BA713D",
    },
    dark_blue: {
      main: "#4C6069",
    }
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
