import { Typography, Box, Grid, Button } from "@mui/material";
import { SectionTitleTypography, PrizeBlock } from "./Home";
import BasePage from "./BasePage";
import { HKGrotesk, qrCodeHeight, qrCodeWidth } from "../const";
import { Link } from 'react-router-dom';

function getNestedCustomFont(str, props) {
  return (
    <Box display='inline' {...props}>{str}</Box>
  )
}


function JoinUsNowSection({ titleText, joinButtonUrl }) {
  const winPrizeFontSize = {
    lg: '35px',
    md: '34px',
    sm: '30px',
    xs: '28px',
  }
  const joinUsNowFontSize = {
    lg: '100px', 
    md: '100px',
    sm: '70px',
    xs: '70px',
  };
  const registerFontSize = '40px';
  const prizeImageHeight = '280px';

  return (
    <Box
      sx={{
        backgroundImage: "url('running_man_with_color.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      // hide overflow button
      position='relative' overflow='hidden'
      pt={14}
      pb={10}
      px={{
        xs: 5,
        sm: 5,
        md: 5,
        lg: 18,
        xl: 10,
      }}
    >
      <Grid container>
        <Grid item lg={7} md={12} sm={12} xs={12}>
          <Box
            borderRadius='20px'
            backgroundColor='yellow.main'
            px={7}
            py={3}
          // mb={{ lg: 0, md: 10, sm: 10, xs: 10 }}
          >
            <Typography
              fontWeight='bold'
              fontFamily={HKGrotesk}
              fontSize={winPrizeFontSize}
            >
              {[
                titleText,
                <br />,
                '使用專屬經銷商碼93198139',
                <br />,
                '前200名立刻抽大獎!',
              ]}
            </Typography>
          </Box>
          <PrizeBlock prizeImageHeight={prizeImageHeight}/>
          <Box
            component='img'
            src='/down_arrow_yellow.svg'
            position='absolute'
            left='0%'
            height='300px'
            ml='-60px'
            mt='-180px'
          />
        </Grid>

        {/* JOIN US NOW button */}
        <Grid item lg={5} md={12} sm={12} xs={12} minHeight='700px'>

          <Link to={joinButtonUrl} style={{ textDecoration: 'none' }} target="_blank">
            <Box
              borderRadius='100%'
              height='530px'
              width='530px'
              bgcolor='yellow.main'
              position='absolute'
              right='0%'
              mr={-10}
              mt={10}
              // ml={-12}
              p={{lg: 7, md: 7, sm: 10, xs: 10}}
              pt={{lg: '110px', md: '110px', sm: '130px', xs: '130px'}}
            >
              <Typography fontSize={joinUsNowFontSize} lineHeight='110%' fontStyle='italic' color='black.main'>
                JOIN US
                <br />
                NOW
              </Typography>
              <Typography component='div' fontSize={registerFontSize} fontWeight='bold' ml={1} lineHeight='200%' color='black.main'>
                點我註冊
              </Typography>
              <Box
                component='img'
                src='/right_arrow_black.svg'
                height='100px'
                position='absolute'
                right='0%'
                mr={{lg: '100px', md: '100px', sm: '80px', xs: '80px'}}
                mt='-80px'
              />
            </Box>
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

function TutorialSection({ titleText, tutorialLines, joinButtonUrl, textAboveJoinButton }) {
  const tutorialFontSize = {
    lg: '35px',
    md: '34px',
    sm: '30px',
    xs: '26px',
  }
  const lineTextFontSize = {
    lg: '40px',
    md: '37px',
    sm: '30px',
    xs: '26px',
  }
  const textAboveJoinButtonFontSize = {
    lg: '35px',
    md: '34px',
    sm: '30px',
    xs: '28px',
  }
  return (
    <Box
      sx={{
        backgroundImage: "url('sports_field_background.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(0,0,0,0.6)',
        backgroundBlendMode: 'darken',
      }}
      pt={14}
      pb={20}
    >
      <SectionTitleTypography titleText={titleText} />
      <Box
        px={{
          xs: 5,
          sm: 5,
          md: 5,
          lg: 10,
          xl: 13,
        }}
      >
        <Grid container justifyContent='space-between' >
          <Grid item lg={6.5} md={12} sm={12} xs={12}>
            <Box
              borderRadius='20px'
              backgroundColor='yellow.main'
              p={5}
              pb={2}
              mb={{ lg: 0, md: 10, sm: 10, xs: 10 }}
            >
              {tutorialLines.map((lines) => {
                return (
                  <Box pb={5}>
                    {lines.map((line) => {
                      // parse and filter alpha numeric
                      let currentStr = '';
                      let currentAlphaNumeric = ''
                      const result = [];
                      const getAlphaNumericFont = (str) => (
                        getNestedCustomFont(str, { fontStyle: 'italic', fontWeight: 'normal', fontSize: tutorialFontSize })
                      )
                      for (let i = 0; i < line.length; i++) {
                        const code = line.charCodeAt(i);
                        const c = line[i];
                        if (!(code > 47 && code < 58) && // numeric (0-9)
                          !(code > 64 && code < 91) && // upper alpha (A-Z)
                          !(code > 96 && code < 123)) { // lower alpha (a-z)
                          currentStr += c;
                          // append special format for alpha numeric characters
                          if (currentAlphaNumeric) {
                            result.push(
                              getAlphaNumericFont(currentAlphaNumeric)
                            );
                            currentAlphaNumeric = '';
                          }
                        }
                        else {
                          currentAlphaNumeric += c;
                          if (currentStr) {
                            result.push(currentStr);
                            currentStr = '';
                          }
                        }
                      }
                      if (currentStr) {
                        result.push(currentStr);
                      }
                      if (currentAlphaNumeric) {
                        result.push(getAlphaNumericFont(currentAlphaNumeric));
                      }
                      return (
                        <Typography
                          component='div'
                          fontSize={tutorialFontSize}
                          fontWeight='bold'
                        >
                          {result}
                        </Typography>
                      );
                    }
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item lg={5.5} md={6} sm={12} xs={12}>
            <Box display='flex' flexDirection='column' alignItems='center' height='100%'>
              <Box flexGrow={1} />
              <Box flexGrow={1}>
                <Typography
                  fontFamily={HKGrotesk}
                  color='white.main'
                  fontSize={textAboveJoinButtonFontSize}
                  fontWeight={600}
                >
                  {textAboveJoinButton}
                </Typography>
                <Typography
                  fontFamily={HKGrotesk}
                  color='white.main'
                  fontSize={textAboveJoinButtonFontSize}
                  fontWeight={600}
                >
                  使用專屬經銷商碼93198139
                </Typography>
                <Button
                  size='large'
                  variant='contained'
                  color='yellow'
                  disableElevation={true}
                  href={joinButtonUrl}
                  target='_blank'
                  sx={{
                    mt: 5,
                    fontWeight: 900,
                    fontFamily: HKGrotesk,
                    width: {lg: '280px', md: '280px', sm: '100%', xs: '100%'},
                    fontSize: '38px',
                    borderRadius: '20px',
                    lineHeight: '70px',
                  }}
                >
                  立即加入
                </Button>
              </Box>
              <Box flexGrow={2} />
            </Box>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Box
              borderRadius='20px'
              backgroundColor='line_green.main'
              mt={5}
              mr={{lg: 5, md: 5, sm: 0, xs: 0}}
              p={5}
            >
              <Typography
                component='div'
                color='white.main'
                fontSize={lineTextFontSize}
                fontWeight='bold'
              >
                {[
                  '點擊連結加入官方',
                  getNestedCustomFont('LINE ', { fontStyle: 'italic', fontWeight: 'normal' }),
                  '客服，專人為您講解步驟。',
                ]}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2} md={6} sm={12} xs={12} mt={{ lg: -7, md: 5, sm: 5, xs: 5 }}>
            <Box display='flex' justifyContent='center' maxWidth='100%'>
              <Box
                component='img'
                src='/line_qrcode.jpg'
                width={qrCodeWidth}
                height={qrCodeHeight}
              />
            </Box>
          </Grid>
          <Grid item lg={2} md={6} sm={12} xs={12} mt={{ lg: -7, md: 5, sm: 5, xs: 5 }}>
            <Box display='flex' justifyContent='center'>
              <Box
                component='img'
                src='/a_qrcode.jpg'
                width={qrCodeWidth}
                height={qrCodeHeight}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export function RegisterNew() {
  const joinUsNowTitleText = '👉新會員註冊';
  const tutorialTitleText = '新會員註冊教學';
  const tutorialLines = [
    [
      '1. 點擊連結前往台灣運彩官方網站:',
      '第二屆線上會員申請成為第三屆線上會員。',
    ],
    [
      '2. 輸入身分證號及驗證碼驗證',
    ],
    [
      '3. 接收手機簡訊驗證，並且輸入。',
      '需關閉手機WIFI 功能，進行身分識別。',
    ],
    [
      '4. 點選同意成為第三屆會員，',
      '並確認指定證號欄位數字93198139。 ',
    ],
    [
      '5. 確認證號為93198139 後點選儲存並完成',
    ],
    [
      '6. 截圖申請完成的頁面，傳至LINE，領取獎勵。',
    ],
  ]
  const joinButtonUrl = 'https://channel.sportslottery.com.tw/zh-tw/register/step1?retailerId=93198139';
  const textAboveJoinButton = '官方會員註冊';

  return (
    <BasePage>
      <JoinUsNowSection
        titleText={joinUsNowTitleText}
        joinButtonUrl={joinButtonUrl}
      />
      <TutorialSection
        titleText={tutorialTitleText}
        tutorialLines={tutorialLines}
        joinButtonUrl={joinButtonUrl}
        textAboveJoinButton={textAboveJoinButton}
      />
    </BasePage>
  )
}

export function Register2To3() {
  const joinUsNowTitleText = '👉第二屆轉第三屆會員';
  const tutorialTitleText = '二屆轉三屆會員教學';
  const tutorialLines = [
    [
      '1. 點擊連結前往台灣運彩官方會員註冊頁面'
    ],
    [
      '2. 輸入身分證號及驗證碼驗證'
    ],
    [
      '3. 接收手機簡訊驗證，並且輸入。',
      '需關閉手機WIFI 功能，進行身分識別。'
    ],
    [
      '4. 點選同意成為第三屆會員，',
      '並確認指定證號欄位數字93198139。'
    ],
    [
      '5. 確認證號為93198139 後點選儲存並完成'
    ],
    [
      '6. 截圖申請完成的頁面，傳至LINE ，領取獎勵。'
    ],
  ];
  const joinButtonUrl = 'https://transfer.sportslottery.com.tw/step1?thirdrid=93198139';
  const textAboveJoinButton = '二屆轉三屆會員';

  return (
    <BasePage>
      <JoinUsNowSection
        titleText={joinUsNowTitleText}
        joinButtonUrl={joinButtonUrl}
      />
      <TutorialSection
        titleText={tutorialTitleText}
        tutorialLines={tutorialLines}
        joinButtonUrl={joinButtonUrl}
        textAboveJoinButton={textAboveJoinButton}
      />
    </BasePage>
  )
}